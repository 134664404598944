.main-container {
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
}

.main-container .logo {
  max-width: 18vw;
  align-self: center;
}

.main-container ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-top: 50px;
}

.main-container ul li {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
  margin-bottom: 20px; /* Espaçamento entre os cards */
}

.main-container ul li img {
  max-width: 100%;
  border-radius: 5px 5px 0 0;
}

.main-container ul li footer {
  flex: 1;
  background: var(--card-color);
  border: 1px solid var(--border);
  padding: 15px 20px;
  text-align: left;
  border-radius: 0 0 5px 5px;
}

.main-container ul li footer strong {
  font-size: 16px;
  color: var(--primary-color);
}

.main-container ul li footer span {
  font-size: 13px;
  color: var(--third-color);
  margin-top: 5px;
}

.main-container ul li footer p {
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-color);
  margin-top: 5px;
}

.main-container ul li .buttons {
  margin-top: 10px;
}

.main-container ul li .buttons button {
  height: 70px;
  width: 70px;
  margin: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  border: 0;
  border-radius: 50px;
  background: var(--card-color);
  cursor: pointer;
}

.main-container ul li .buttons button img {
  width: 25px;
  transition: all 0.2s; /* Transição movida para aqui */
}

.main-container ul li .buttons button:hover img {
  transform: scale(1.1);
}

.main-container .empty {
  font-size: 32px;
  color: #999;
  font-weight: bold;
  margin-top: 300px;
}

.match-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
}

.match-container .avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 5px solid #fff;
  margin: 30px 0;
}

.match-container strong {
  font-size: 32px;
  color: #fff;
}

.match-container p {
  margin-top: 10px;
  font-size: 20px;
  line-height: 30px;
  max-width: 400px;
  color: rgba(255, 255, 255, 0.8);
}

.match-container button {
  border: 0;
  background: none;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
  cursor: pointer;
}

.view-matches {
  margin-top: 20px;
  background-color: #842f2a; /* cor anteriormente estava como laranja mas estava comentado como vermelho, mantive o vermelho */
  border: none;
  color: white;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  padding: 10px 20px;
}

.view-matches:hover {
  background-color: white;
  color: black;
}

@media (max-width: 980px) {
  .main-container ul {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .main-container ul {
    grid-template-columns: 1fr;
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 70vw;
  max-width: 500px;
  margin: 50px auto;
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  background: var(--card-color);
}

.card footer {
  padding: 15px;
  text-align: left;
}

.card .buttons {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
}

.card .card-image {
  width: 100%;
  object-fit: cover;
  height: 40vh;
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.buttons button {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  padding: 18px 22px;
  border-radius: 50%;
}


.btn-grad {
  background-image: linear-gradient(to right, #a73737 0%, #7a2828  51%, #a73737  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
