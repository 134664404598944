:root {
  --bg-color: #fff9fd;
  --card-color: #fff;
  --primary-color: #333;
  --secondary-color: #999;
  --third-color: #ccc;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  height: 100%;
}

body {
  background-color: var(--bg-color);
  color: var(--font-color);
}

body,
input,
button {
  font-family: Arial, Helvetica, sans-serif;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: '';
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
