.login-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.login-container form .logo {
  max-width: 255px;
  align-self: center;
}

.login-container form input {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 48px;
  padding: 0 20px;
  font-size: 16px;
  width: 100%;
  color: #666;
}

.login-container form input ::placeholder {
  color: #999;
}

.login-title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #ff2cff;
  margin-bottom: 10px;
  margin-top: 15px;
}

.login-container form button {
  background-image: linear-gradient(to right, #a73737 0%, #7a2828  51%, #a73737  100%);
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 100%;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  margin-top: 4vh;
}


.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.file-upload {
  display: block;
  width: 100%;
  height: 40px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  line-height: 40px;
  text-align: center;
  background-color: #f4f4f4;
  cursor: pointer;
}

.file-upload input[type="file"] {
  display: none;
}
