/* Estilo global para fontes */
body {
    font-family: 'Arial', sans-serif;
}

/* Estilos para os Matches */
.matches-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.match-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); /* Sombra adicionada */
    cursor: pointer;
    transition: background-color 0.3s;
}

.match-item:hover {
    background-color: #f5f5f5;
}

/* Estilos para o Chat */
.chat-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 75vh;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); /* Sombra adicionada */
    z-index: 2;
    margin-bottom: 10px;
}

.chat-header {
    display: flex;
    justify-content: space-between; /* Adicionado para espaçamento */
    align-items: center;
    padding: 10px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ddd;
}

.chat-messages {
    display: flex; /* Adicionado para que align-self funcione nas mensagens */
    flex-direction: column;
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.message {
    max-width: 70%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    color: white;
}

.message.mine {
    align-self: flex-end;
    background-color: black;
}

.message.theirs {
    align-self: flex-start;
    background-color: #007BFF;
}

.chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
}

input[type="text"] {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.close-chat {
    background-color: transparent;
    border: none;
    font-size: 20px;
    line-height: 1;
    padding: 5px 10px;
    cursor: pointer;
    color: #888;  /* Define a cor padrão do ícone como cinza */
    transition: color 0.3s;
}

.close-chat:hover {
    color: #666; /* Escurece um pouco quando passa o mouse por cima */
    background-color: transparent;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.match-name {
    font-size: 18px;
    font-weight: bold;
    color: #842f2a;
    margin-top: 10px;
}

.btn-grad {
    padding: 15px 20px;
    margin: 0px;
}

.match-header {
  color: #842f2a;
}
